import React from 'react'
import { Link } from 'react-router-dom'
export default function NotFound() {
    return (
        <div>
            <img src="./img/404.jpg" alt="" />
            <div>
                <Link className='btn btn-warning' style={{ position: "absolute", top: "10%", left: "50%" }} to="/">
                    Back To Home
                </Link>
            </div>
        </div>

    )
}
