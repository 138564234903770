import './App.css';
import {
  Routes,
  Route, useLocation
} from "react-router-dom";
import { useState, useEffect } from 'react'
import secureLocalStorage from "react-secure-storage";
import Home from './components/Home'
import Login from './components/Login'
import Report from './components/Report Management/Report'
import Doctor from './components/Staff Management/Doctor'
import Location from './components/Location Management/Location'
import Pcm from './components/Staff Management/Pcm'
import Consultant from './components/Staff Management/Consultant'
import Department from './components/Department Management/Department';
import Version from './components/version Management/Version';
import TeleCaller from './components/Staff Management/TeleCaller';
import MisOperator from './components/Staff Management/MisOperator';
import NotFound from './components/NotFound';
function App() {
  // const navigate = useNavigate()
  const location = useLocation();
  const pathName = location.pathname;
  const [path, setPath] = useState("");
  secureLocalStorage.setItem("hitech_path", JSON.stringify(pathName));
  useEffect(() => {
    setPath(JSON.parse(secureLocalStorage.getItem("hitech_path")));
  }, [pathName]);
  const updateAdmin = (user) => {
    secureLocalStorage.setItem("hitech-panel", JSON.stringify(user))
    setLoginUser(user)
  }
  const [user, setLoginUser] = useState({})
  useEffect(() => {
    setLoginUser(JSON.parse(secureLocalStorage.getItem("hitech-panel")));
  }, [])
  // console.log(user)
  const adminToken = (t) => {
    secureLocalStorage.setItem("hitech-panel-token", JSON.stringify(t))
    setToken(t)
  }
  const [token, setToken] = useState("")
  useEffect(() => {
    setToken(JSON.parse(secureLocalStorage.getItem("hitech-panel-token")));
  }, [])
  // console.log(user)
  return (
    <div >
      <Routes>
        <Route exact path='/' element={user && user.users_id ? <Home updateAdmin={updateAdmin} user={user} token={token} /> : <Login updateAdmin={updateAdmin} adminToken={adminToken} />} />

        <Route path='/report' element={user && user.users_id ? <Report updateAdmin={updateAdmin} user={user} token={token} /> : <Login updateAdmin={updateAdmin} adminToken={adminToken} />} />
        {
          user && user.users_type === 6 &&
          <Route path='/doctor' element={user && user.users_id ? <Doctor updateAdmin={updateAdmin} user={user} token={token} /> : <Login updateAdmin={updateAdmin} adminToken={adminToken} />} />
        }
        {
          user && user.users_type === 6 &&
          <Route path='/location' element={user && user.users_id ? <Location updateAdmin={updateAdmin} user={user} token={token} /> : <Login updateAdmin={updateAdmin} adminToken={adminToken} />} />
        }
        {
          user && user.users_type === 6 &&
          <Route path='/pcm' element={user && user.users_id ? <Pcm updateAdmin={updateAdmin} user={user} token={token} /> : <Login updateAdmin={updateAdmin} adminToken={adminToken} />} />
        }
        {
          user && user.users_type === 6 &&
          <Route path='/consultant' element={user && user.users_id ? <Consultant updateAdmin={updateAdmin} user={user} token={token} /> : <Login updateAdmin={updateAdmin} adminToken={adminToken} />} />
        }
        {
          user && user.users_type === 6 &&
          <Route path='/tele_caller' element={user && user.users_id ? <TeleCaller updateAdmin={updateAdmin} user={user} token={token} /> : <Login updateAdmin={updateAdmin} adminToken={adminToken} />} />
        }
        {
          user && user.users_type === 6 &&
          <Route path='/mis_operator' element={user && user.users_id ? <MisOperator updateAdmin={updateAdmin} user={user} token={token} /> : <Login updateAdmin={updateAdmin} adminToken={adminToken} />} />
        }
        {
          user && user.users_type === 6 &&
          <Route path='/department' element={user && user.users_id ? <Department updateAdmin={updateAdmin} user={user} token={token} /> : <Login updateAdmin={updateAdmin} adminToken={adminToken} />} />
        }
        {
          user && user.users_type === 6 &&
          <Route path='/version' element={user && user.users_id ? <Version updateAdmin={updateAdmin} user={user} token={token} /> : <Login updateAdmin={updateAdmin} adminToken={adminToken} />} />
        }
        <Route path="*" element={<NotFound />} />
        <Route path="/login" element={<Login updateAdmin={updateAdmin} adminToken={adminToken} path={path} />} />
      </Routes>
    </div>
  );
}

export default App;
