import React, { useState, useEffect } from "react";
import TopNav from "../TopNav";
import SideNav from "../SideNav";
import { Form, Formik, Field, useFormik } from "formik";
import { addDoctor, editDoctor } from "../schemas";
import axios from "axios";
import { url } from "../Url";
import { Drawer, Box } from "@mui/material";
import Loader from '../Loader'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '../Pagination'
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
// import moment from "moment/moment";
const weekdays = [
    {
        weekID: "0",
        weekDay: "Monday",
    },
    {
        weekID: "1",
        weekDay: "Tuesday",
    },
    {
        weekID: "2",
        weekDay: "Wednesday",
    },
    {
        weekID: "3",
        weekDay: "Thursday",
    },
    {
        weekID: "4",
        weekDay: "Friday",
    },
    {
        weekID: "5",
        weekDay: "Saturday",
    },
    {
        weekID: "6",
        weekDay: "Sunday",
    },
]
const Doctor = ({ updateAdmin, user, token }) => {
    // console.log(currentDate)
    const [doctorName, setDoctorName] = useState("");
    const [empId, setEmpId] = useState("")
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [counter, setCounter] = useState(0);
    const showPerPage = 10;
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });
    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [loader, setLoader] = useState(false);

    const [userId, setUserId] = useState("");
    const [userNo, setUserNo] = useState("");
    const [userName, setUserName] = useState("");
    const [userCityName, setUserCityName] = useState("");
    const [userStateName, setUserStateName] = useState("");
    const [userGender, setUserGender] = useState("");
    const [userMobile, setUserMobile] = useState("");
    const [userDob, setUserDob] = useState("");
    const [userAddress, setUserAddress] = useState("");
    const [userPin, setUserPin] = useState("");
    // const [userDepartment, setUserDepartment] = useState("");
    const [departmentId, setDepartmentId] = useState("");
    const [userDoctorRegdNo, setUserDoctorRegdNo] = useState("");
    const [doctorExp, setDoctorExp] = useState("");
    const [doctorQualification, setDoctorQualification] = useState("");
    const [scheduledDetails, setScheduleDetails] = useState([]);
    const [locationId, setLocationId] = useState("");

    const initialValues = {
        users_no: "",
        users_password: "",
        users_name: "",
        users_city_name: "",
        users_state_name: "",
        users_gender: "",
        users_type: "2",
        users_mobile: "",
        users_dob: "",
        users_address: "",
        users_pin: "",
        users_depart_id: "",
        users_doctor_reg_no: "",
        users_doctor_exp: "",
        users_qualification: ""
    }
    const [addDoctorScheduled, setAddDoctorScheduled] = useState([
        {
            day: "",
            in_time: "",
            out_time: "",
        },
    ]);
    const { day, in_time, out_time } = addDoctorScheduled[addDoctorScheduled.length - 1]

    const { values, touched, errors, handleBlur, handleSubmit, handleChange } = useFormik({
        initialValues: initialValues,
        validationSchema: addDoctor,
        onSubmit: (values, action) => {
            if (values && day !== "" && in_time !== "" && out_time !== "") {
                handleAdd(values);
                action.resetForm();
            } else {
                toast.error("Some Fields Are Misssing", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        },
    });
    // console.log(errors);
    const savedValues = {
        users_id: userId,
        users_name: userName,
        users_city_name: userCityName,
        users_state_name: userStateName,
        users_dob: userDob,
        users_mobile: userMobile,
        users_address: userAddress,
        users_pin: userPin,
        users_gender: userGender,
        users_depart_id: departmentId,
        users_qualification: doctorQualification,
        users_doctor_exp: doctorExp,
        users_doctor_reg_no: userDoctorRegdNo
    }
    const [location, setLocation] = useState({
        location_id: "",
    });
    // console.log(location);
    const handleLocation = (e) => {
        let { name, value } = e.target;
        setLocation({
            ...location,
            [name]: value,
        });
    };

    // console.log(addDoctorScheduled)

    const handleInputs = (e, i) => {
        const { name, value } = e.target;
        let newFormValues = [...addDoctorScheduled];
        newFormValues[i][name] = value;
        setAddDoctorScheduled(newFormValues);
        console.log(value);
    };

    // console.log("addDoctorScheduledadd", addDoctorScheduled)

    const addDoctorSchedule = () => {
        setAddDoctorScheduled([
            ...addDoctorScheduled,
            {
                day: "",
                in_time: "",
                out_time: "",
            },
        ]);
    };
    const removeDoctorSchedule = (i) => {
        const list = [...addDoctorScheduled];
        list.splice(i, 1);
        setAddDoctorScheduled(list);
    };

    const handleAdd = async (values) => {
        setLoader(true);
        await axios
            .post(`${url}/addStaff?_format=json`, values, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            .then((res) => {
                if (res.status === 200) {
                    doctorApi(res.data.data);
                } else {
                    setLoader();
                    toast.error("Doctor added failed", {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        pauseOnHover: true,
                        theme: "colored",
                    });
                }
            })
            .catch((err) => {
                setLoader();
                toast.error(err.response.data.msg, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
                if (err.response.status === 422 || err.response.status === 401) {
                    updateAdmin({});
                }
            });
    };

    const doctorApi = async (data) => {
        // console.log(addDoctorScheduled);
        await axios
            .post(`${url}/addDoctorScheduled?_format=json`, {
                "doctor_id": data,
                "schedules": addDoctorScheduled,
            }, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            .then((res) => {
                // console.log(res);
                if (res.status === 200) {
                    doctorLocationApi(data);
                    getDocList();
                } else {
                    setLoader();
                    toast.error("Doctor add Failed", {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        pauseOnHover: true,
                        theme: "colored",
                    });

                }
            })
            .catch((err) => {
                // console.log(err.response.data.msg);
                toast.error(err.response.data.msg, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
                setLoader();
                if (err.response.status === 422 || err.response.status === 401) {
                    updateAdmin({});
                }
            });
    };
    const doctorLocationApi = async (data) => {
        const { location_id } = location;
        // console.log(location_id)
        await axios
            .post(`${url}/addLocationDoctor?_format=json`, {
                doctor_id: data,
                location_id,
            }, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            .then((res) => {
                // console.log(res);
                if (res.status === 200) {

                    setLoader();
                    setIsDrawerOpen();
                    getDocList();
                    toast.success("Doctor add Successfully", {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        pauseOnHover: true,
                        theme: "colored",
                    });
                } else {
                    setLoader();
                    toast.error("Doctor add Failed", {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        pauseOnHover: true,
                        theme: "colored",
                    });
                }
            })
            .catch((err) => {
                // console.log(err.response.data.msg);
                toast.error(err.response.data.msg, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
                setLoader();
                if (err.response.status === 422 || err.response.status === 401) {
                    updateAdmin({});
                }
            });
    };

    const [getDepartments, setDepartments] = useState([]);
    const getDepartment = () => {
        axios.get(`${url}/getDepartmentDetails?_format=json`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            if (res.status === 200) {
                setDepartments(res.data.data);
            } else {
                toast.error("no data found", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    };
    useEffect(() => {
        getDepartment();
    }, []);

    const [getLocations, setLocations] = useState([]);
    const getLocation = () => {
        axios.get(`${url}/getLocationList?_format=json`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            res.status === 200 ? setLocations(res.data.data) : toast.error("no data found", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                theme: "colored",
            });
        }).catch((err) => {

            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    };
    useEffect(() => {
        getLocation();
    }, []);

    const [getDoctors, seGetDoctors] = useState([]);
    const [lastEmpId, setLastEmpId] = useState("");
    const getDocList = () => {
        counter ? setLoader() : setLoader(true);
        axios.get(`${url}/getDoctorDetails?_format=json`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            // console.log(res)
            if (res.status === 200) {
                setLoader();
                seGetDoctors(res.data.data);
                setLastEmpId(res.data.data[res.data.data.length - 1].users_no)
            } else {
                toast.error("no data found", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            if (err.response.data.code === "404") {
                toast.error("No doctor details available", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
            setLoader();
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    };
    useEffect(() => {
        getDocList();
    }, [counter]);

    //Drawer Edit
    const [drawerEditOpen, setDrawerEditOpen] = useState(false);
    const DocEdit = (data) => {
        // console.log(data)
        setDrawerEditOpen(true);
        setUserId(data.users_id);
        setUserNo(data.users_no);
        setUserName(data.users_name);
        setUserCityName(data.users_city_name);
        setUserStateName(data.users_state_name);
        setUserGender(data.users_gender);
        setUserMobile(data.users_mobile);
        setUserDob(data.users_dob);
        setUserAddress(data.users_address);
        setLocationId(data.location_id)
        setUserPin(data.users_pin);
        setDepartmentId(data.departments_id);
        setUserDoctorRegdNo(data.users_doctor_reg_no);
        setDoctorExp(data.users_doctor_exp);
        setDoctorQualification(data.users_qualification);
        setScheduleDetails(JSON.parse(data.scheduled_details));
    };

    // -----------------------------
    const handleEditInputs = (e, i) => {
        let newFormValues = [...scheduledDetails];
        newFormValues[i][e.target.name] = e.target.value;
        setScheduleDetails(newFormValues);
    };

    const removeEditDoctorSchedule = (i) => {
        const list = [...scheduledDetails];
        list.splice(i, 1);
        setScheduleDetails(list);
    };
    const addEditDoctorSchedule = () => {
        setScheduleDetails([
            ...scheduledDetails,
            {
                day: "",
                in_time: "",
                out_time: "",
            },
        ]);
    };

    //  edit Doctor
    const doctorEdit = async () => {
        setLoader(true);
        await axios.post(`${url}/updateStaffDetails?_format=json`, savedValues, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        }).then((res) => {
            if (res.data.code === "200") {
                scheduleEdit();
            } else {
                setLoader();
                toast.error("Update Failed", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            toast.error(err.response.data.msg, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                theme: "colored",
            });
            setLoader();
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        });
    };
    const scheduleEdit = async () => {
        if (scheduledDetails !== null) {
            const { day, in_time, out_time } = scheduledDetails[scheduledDetails.length - 1]
            console.log(day, in_time, out_time);
            if (day !== "" && in_time !== "" && out_time !== "") {
                await axios
                    .post(`${url}/addDoctorScheduled?_format=json`, {
                        "doctor_id": userId,
                        "schedules": scheduledDetails === null ? addDoctorScheduled : scheduledDetails,
                    }, {
                        headers: {
                            Authorization: 'Bearer ' + token
                        }
                    })
                    .then((res) => {
                        // console.log(res);
                        if (res.status === 200) {
                            locationEdit();
                        } else {
                            setLoader();
                            toast.error("Doctor Update Failed", {
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                pauseOnHover: true,
                                theme: "colored",
                            });
                        }

                    })
                    .catch((err) => {
                        toast.error(err.response.data.msg, {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            pauseOnHover: true,
                            theme: "colored",
                        });
                        setLoader();
                        if (err.response.status === 422 || err.response.status === 401) {
                            updateAdmin({});
                        }
                    });
            } else {
                setLoader();
                toast.error("Provide doctor schedule", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        } else {
            setLoader();
            toast.error("Provide doctor schedule", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                theme: "colored",
            });
        }
    }
    const locationEdit = async () => {
        if (locationId) {
            await axios
                .post(`${url}/addLocationDoctor?_format=json`, {
                    doctor_id: userId,
                    "location_id": parseInt(locationId),
                }, {
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                })
                .then((res) => {
                    // console.log(res);
                    if (res.status === 200) {
                        getDocList();
                        setLoader();
                        setDrawerEditOpen();
                        toast.success("Doctor Update Successfully", {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            pauseOnHover: true,
                            theme: "colored",
                        });
                    } else {
                        toast.error("Doctor Update Failed", {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            pauseOnHover: true,
                            theme: "colored",
                        });
                    }
                })
                .catch((err) => {
                    setLoader();
                    toast.error(err.response.data.msg, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        pauseOnHover: true,
                        theme: "colored",
                    });
                    if (err.response.status === 422 || err.response.status === 401) {
                        updateAdmin({});
                    }
                });
        } else {
            setLoader();
            toast.error("Provide doctor location", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                theme: "colored",
            });
        }
    };
    const changeDoctorStatus = async (data) => {
        let { users_id, users_active } = data;
        if (users_active === 1) {
            users_active = 0;
        } else {
            users_active = 1;
        }
        await axios
            .post(`${url}/updateStaffStatus?_format=json`, {
                "users_id": users_id,
                "users_active": users_active,
            }, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
            .then((res) => {
                if (res.data.code === "200") {
                    setCounter((c) => c + 1);
                    toast.success(res.data.msg, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        pauseOnHover: true,
                        theme: "colored",
                    });
                } else {
                    setCounter((c) => c + 1);
                    toast.success(res.data.msg, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        pauseOnHover: true,
                        theme: "colored",
                    });
                }
            })
            .catch((err) => {
                toast.error(err.response.data.msg, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
                if (err.response.status === 422 || err.response.status === 401) {
                    updateAdmin({});
                }
            });
    };

    const [activeDoctor, setActiveDoctor] = useState('active');
    const handleActiveDoctor = (e) => {
        setActiveDoctor(e.target.value);
    };

    const EditDrawerTime = () => {
        return (
            <>
                {scheduledDetails?.map((item, i) => {
                    // console.log(item);
                    return (
                        <div key={i} className="d-flex">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label className="form-label">Choose Schedule Day <span className="text-danger">*</span></label>
                                    <select className="form-control" name="day" value={item?.day} onChange={(e) => handleEditInputs(e, i)}>
                                        <option value="">---Choose Schedule Day---</option>
                                        {
                                            weekdays.map((val, i) => {
                                                return (
                                                    <option key={i} value={val.weekID}>{val.weekDay}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-lg-6 ml-4">
                                        <div className="mb-3">
                                            <label className="form-label mx-2">In Time <span className="text-danger">*</span></label>
                                            <input type="time" className="form-control mx-2" name="in_time" placeholder="Enter In Time" value={item?.in_time} onBlur={handleBlur} onChange={(e) => handleEditInputs(e, i)} />
                                            {errors?.in_time && touched?.in_time ? <p className="form-valid">{errors?.in_time}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Out Time <span className="text-danger">*</span></label>
                                            <input type="time" className="form-control" name="out_time" placeholder="Enter Out Time" value={item?.out_time} onBlur={handleBlur} onChange={(e) => handleEditInputs(e, i)} />

                                            {errors?.out_time && touched?.out_time ? <p className="form-valid">{errors?.out_time}</p> : null}
                                        </div>

                                        <div className={"d-flex justify-content-end m-0"}>
                                            {scheduledDetails.length !== 1 && (
                                                <button type="button" onClick={() => removeEditDoctorSchedule(i)} className="btn btn-danger mx-2">
                                                    <i className="fas fa-minus"></i>
                                                </button>
                                            )}
                                            {scheduledDetails.length - 1 === i && (
                                                <button
                                                    type="button"
                                                    onClick={() => addEditDoctorSchedule()}
                                                    className={scheduledDetails.length > 6 ? "btn btn-primary d-none" : "btn btn-primary"}
                                                >
                                                    <i className="fas fa-plus"></i>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };
    const EditDrawerTimeAdd = () => {
        return (
            <>
                {addDoctorScheduled?.map((item, i) => {
                    // console.log(item?.day);
                    return (
                        <div key={i} className="d-flex">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label className="form-label">Choose Schedule Day <span className="text-danger">*</span></label>
                                    <select className="form-control" name="day" value={item?.day} onChange={(e) => handleInputs(e, i)}>
                                        <option value="">---Choose Schedule Day---</option>
                                        {
                                            weekdays.map((val, i) => {
                                                return (
                                                    <option key={i} value={val.weekID}>{val.weekDay}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label mx-2">In Time <span className="text-danger">*</span></label>
                                            <input type="time" className="form-control mx-2" name="in_time" placeholder="Enter In Time" value={item?.in_time} onBlur={handleBlur} onChange={(e) => handleInputs(e, i)} />
                                            {errors?.in_time && touched?.in_time ? <p className="form-valid">{errors?.in_time}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Out Time <span className="text-danger">*</span></label>
                                            <input type="time" className="form-control" name="out_time" placeholder="Enter Out Time" value={item?.out_time} onBlur={handleBlur} onChange={(e) => handleInputs(e, i)} />
                                            {errors?.out_time && touched?.out_time ? <p className="form-valid">{errors?.out_time}</p> : null}
                                        </div>

                                        <div className={"d-flex justify-content-end m-0"}>
                                            {addDoctorScheduled.length !== 1 && (
                                                <button type="button" onClick={() => removeDoctorSchedule(i)} className="btn btn-danger mx-2">
                                                    <i className="fas fa-minus"></i>
                                                </button>
                                            )}
                                            {addDoctorScheduled.length - 1 === i && (
                                                <button
                                                    type="button"
                                                    onClick={() => addDoctorSchedule()}
                                                    className={addDoctorScheduled.length > 6 ? "btn btn-primary d-none" : "btn btn-primary"}
                                                >
                                                    <i className="fas fa-plus"></i>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };
    return (
        <div className="page">
            <SideNav />
            <TopNav updateAdmin={updateAdmin} user={user} token={token} />
            <div className="page-wrapper">
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row g-2 align-items-center">
                            {/* <!-- Page title actions --> */}
                            <div className="col-12 col-md-auto ms-auto d-print-none">
                                <div className="btn-list">
                                    <div onClick={() => setIsDrawerOpen(true)} className="btn btn-primary d-none d-sm-inline-block">
                                        <i className="fas fa-plus"></i> &nbsp; Add Doctors
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2>Doctors List</h2>
                    <div className="container my-3">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="city">Search By Doctor Name </label>
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            placeholder="Enter Doctor Name"
                                            name="searchName"
                                            type="text"
                                            onChange={(e) => setDoctorName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="city">Search By Emp Id </label>
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            placeholder="Enter Employee Id"
                                            name="searchName"
                                            type="text"
                                            onChange={(e) => setEmpId(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 d-flex flex-row-reverse">
                                <ToggleButtonGroup
                                    color={activeDoctor === 'active' ? 'primary' : 'secondary'}
                                    value={activeDoctor}
                                    exclusive
                                    onChange={handleActiveDoctor}
                                    aria-label="Platform"
                                >
                                    <ToggleButton value="active">Active</ToggleButton>
                                    <ToggleButton value="inactive">Inactive</ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </div>
                    </div>
                    {
                        activeDoctor === "active" &&
                        <div className="box-datatable table-responsive" style={{ backgroundColor: "white" }}>
                            <table className="table-striped table table-vcenter card-table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Emp Id</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Mobile No</th>
                                        <th scope="col">dob</th>
                                        <th scope="col">gender</th>
                                        <th scope="col">qualification</th>
                                        <th scope="col">department</th>
                                        <th scope="col">Regd No</th>
                                        <th scope="col">experience (in yrs)</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                {
                                    loader ? <Loader /> :
                                        <tbody>
                                            {getDoctors?.filter((item) => {
                                                if (doctorName === "") {
                                                    return item;
                                                } else if (
                                                    item.users_name.toLocaleLowerCase().includes(doctorName.toLocaleLowerCase())
                                                ) {
                                                    return item;
                                                }
                                                return false;
                                            }).filter((item) => {
                                                if (empId === "") {
                                                    return item;
                                                } else if (
                                                    item.users_no.toLocaleLowerCase().includes(empId.toLocaleLowerCase())
                                                ) {
                                                    return item;
                                                }
                                                return false;
                                            }).filter((val) => val.users_active === 1).slice(pagination.start, pagination.end).map((data, i) => {
                                                // console.log(JSON.parse(data.scheduled_details))
                                                return (
                                                    <tr key={i}>
                                                        <td>{i + 1 + pagination.start}</td>
                                                        <td>{data.users_no}</td>
                                                        <td>{data.users_name}</td>
                                                        <td>{data.users_mobile}</td>
                                                        <td>{data.users_dob?.split('-').reverse().join('/')}</td>
                                                        <td>{data.users_gender === 1 ? "Male" : data.users_gender === 2 ? "Female" : "Other"}</td>
                                                        <td>{data.users_qualification}</td>
                                                        <td>{data.departments_name}</td>
                                                        <td>{data.users_doctor_reg_no}</td>
                                                        <td>{data.users_doctor_exp} yrs</td>
                                                        <td>
                                                            {data.users_active === 1 ? (
                                                                <i onClick={() => changeDoctorStatus(data)} style={{ color: "green", cursor: "pointer" }} className="far fa-check-circle"></i>
                                                            ) : (
                                                                <i onClick={() => changeDoctorStatus(data)} style={{ color: "red", cursor: "pointer" }} className="far fa-times-circle"></i>
                                                            )}
                                                        </td>
                                                        <td onClick={() => DocEdit(data)}>
                                                            <i style={{ color: "green" }} className="fas fa-edit"></i>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                }
                            </table>
                            <div className="mx-auto">
                                <Pagination
                                    showPerPage={showPerPage}
                                    onPaginationChange={onPaginationChange}
                                    total={getDoctors.length}
                                />
                            </div>
                        </div>
                    }
                    {
                        activeDoctor === "inactive" &&
                        <div className="box-datatable table-responsive" style={{ backgroundColor: "white" }}>
                            <table className="table-striped table table-vcenter card-table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Emp Id</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Mobile No</th>
                                        <th scope="col">dob</th>
                                        <th scope="col">gender</th>
                                        <th scope="col">qualification</th>
                                        <th scope="col">department</th>
                                        <th scope="col">Regd No</th>
                                        <th scope="col">experience (in yrs)</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                {
                                    loader ? <Loader /> :
                                        <tbody>
                                            {getDoctors?.filter((val) => val.users_active === 0).map((data, i) => {
                                                console.log(JSON.parse(data.scheduled_details))
                                                 console.log("data- n n actv--->", data)
                                                return (
                                                     <>
                                                      <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{data.users_no}</td>
                                                        <td>{data.users_name}</td>
                                                        <td>{data.users_mobile}</td>
                                                        <td>{data.users_dob?.split("-").reverse().join("/")}</td>
                                                        <td>{data.users_gender === 1 ? "male" : data.users_gender === 2 ? "female" : "transgender"}</td>
                                                        <td>{data.users_qualification}</td>
                                                        <td>{data.departments_name}</td>
                                                        <td>{data.users_doctor_reg_no}</td>
                                                        <td>{data.users_doctor_exp}</td>
                                                        <td>
                                                            {data.users_active === 1 ? (
                                                                <i onClick={() => changeDoctorStatus(data)} style={{ color: "green", cursor: "pointer" }} className="far fa-check-circle"></i>
                                                            ) : (
                                                                <i onClick={() => changeDoctorStatus(data)} style={{ color: "red", cursor: "pointer" }} className="far fa-times-circle"></i>
                                                            )}
                                                        </td>
                                                    </tr>
                                                      </> 
                                                    
                                                );
                                            })}
                                        </tbody>
                                }
                            </table>
                        </div>
                    }
                </div>
            </div>


            {/* Edit data*/}
            <Drawer anchor="right" className="modal-blur" open={drawerEditOpen} onClose={() => setDrawerEditOpen()}>
                <Box width="80rem">
                    <div className="modal-header">
                        <h5 className="modal-title">

                            <span>
                                <i className="fa-solid fa-pencil"></i>
                            </span>
                            Update Doctor
                        </h5>
                        <button type="button" className="btn-close" onClick={() => setDrawerEditOpen()}></button>
                    </div>
                    <div className="container">
                        <Formik
                            initialValues={savedValues}
                            initialTouched={{
                                field: true,
                            }}
                            validationSchema={editDoctor}
                            enableReinitialize
                            onSubmit={doctorEdit}
                        >
                            {({ errors, touched }) => {
                                // console.log(errors)
                                return (
                                    <Form>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label className="form-label">User No <span className="text-danger">*</span></label>
                                                                <Field disabled type="text" className="form-control" name="users_no" value={userNo} onChange={handleChange} onBlur={handleBlur} />

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label className="form-label">Name <span className="text-danger">*</span></label>
                                                                <Field type="text" className="form-control" name="users_name" value={userName} onBlur={handleBlur} onChange={(e) => setUserName(e.target.value)} />
                                                                {errors.users_name && touched.users_name ? <p className="form-valid">{errors.users_name}</p> : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label className="form-label">Mobile <span className="text-danger">*</span></label>
                                                                <Field type="number" className="form-control" name="users_mobile" value={userMobile} onBlur={handleBlur} onChange={(e) => setUserMobile(e.target.value)} />
                                                                {errors.users_mobile && touched.users_mobile ? <p className="form-valid">{errors.users_mobile}</p> : null}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label className="form-label">Date of Birth <span className="text-danger">*</span></label>
                                                                <Field type="date" className="form-control" name="users_dob" value={userDob} onBlur={handleBlur} onChange={(e) => setUserDob(e.target.value)} />
                                                                {errors.users_dob && touched.users_dob ? <p className="form-valid">{errors.users_dob}</p> : null}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label className="form-label">Choose Gender <span className="text-danger">*</span></label>

                                                                <Field as="select" className="form-control" name="users_gender" value={userGender} onBlur={handleBlur} onChange={(e) => setUserGender(e.target.value)}>
                                                                    <option value="">--- Choose gender ---</option>
                                                                    <option value="1">Male</option>
                                                                    <option value="2">Female</option>
                                                                </Field>
                                                                {errors?.users_gender && touched?.users_gender ? <p className="form-valid">{errors?.users_gender}</p> : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label className="form-label">Choose Department <span className="text-danger">*</span></label>

                                                                <Field as="select" className="form-control" name="users_depart_id" value={departmentId} onBlur={handleBlur} onChange={(e) => setDepartmentId(e.target.value)}>
                                                                    <option value=''>--- Choose Department ---</option>
                                                                    {getDepartments.filter((val) => val.departments_status === 1).map((item, i) => {
                                                                        return (
                                                                            <option key={i} value={item.departments_id}>
                                                                                {item.departments_name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </Field>
                                                                {errors?.users_depart_id && touched?.users_depart_id ? <p className="form-valid">{errors?.users_depart_id}</p> : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label className="form-label">Doctor RegdNo <span className="text-danger">*</span></label>
                                                                <Field type="text" className="form-control" name="users_doctor_reg_no" value={userDoctorRegdNo} onBlur={handleBlur} onChange={(e) => setUserDoctorRegdNo(e.target.value)} />
                                                                {errors?.users_doctor_reg_no && touched?.users_doctor_reg_no ? <p className="form-valid">{errors?.users_doctor_reg_no}</p> : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label className="form-label">Doctor Qualification <span className="text-danger">*</span></label>
                                                                <Field type="text" className="form-control" name="users_qualification" value={doctorQualification} onBlur={handleBlur} onChange={(e) => setDoctorQualification(e.target.value)} />
                                                                {errors?.users_qualification && touched?.users_qualification ? <p className="form-valid">{errors?.users_qualification}</p> : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label className="form-label">Doctor Experience (in yrs) <span className="text-danger">*</span></label>
                                                                <Field type="text" className="form-control" name="users_doctor_exp" value={doctorExp} onBlur={handleBlur} onChange={(e) => setDoctorExp(e.target.value)} />
                                                                {errors?.users_doctor_exp && touched?.users_doctor_exp ? <p className="form-valid">{errors?.users_doctor_exp}</p> : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label className="form-label">City Name <span className="text-danger">*</span></label>
                                                                <Field type="text" className="form-control" name="users_city_name" value={userCityName} onBlur={handleBlur} onChange={(e) => setUserCityName(e.target.value)} />
                                                                {errors?.users_city_name && touched?.users_city_name ? <p className="form-valid">{errors?.users_city_name}</p> : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label className="form-label">State Name <span className="text-danger">*</span></label>
                                                                <Field type="text" className="form-control" name="users_state_name" value={userStateName} onBlur={handleBlur} onChange={(e) => setUserStateName(e.target.value)} />
                                                                {errors?.users_state_name && touched?.users_state_name ? <p className="form-valid">{errors?.users_state_name}</p> : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3">
                                                                <label className="form-label">Pin Code <span className="text-danger">*</span></label>
                                                                <Field type="text" className="form-control" name="users_pin" value={userPin} onBlur={handleBlur} onChange={(e) => setUserPin(e.target.value)} />
                                                                {errors?.users_pin && touched?.users_pin ? <p className="form-valid">{errors?.users_pin}</p> : null}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <div>
                                                                <label className="form-label">Address <span className="text-danger">*</span></label>
                                                                <textarea className="form-control" rows="3" name="users_address" value={userAddress} onBlur={handleBlur} onChange={(e) => setUserAddress(e.target.value)}></textarea>
                                                                {errors?.users_address && touched?.users_address ? <p className="form-valid">{errors?.users_address}</p> : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Choose Location <span className="text-danger">*</span></label>
                                                        <Field as="select" className="form-control" name="location_id" value={locationId} onChange={(e) => setLocationId(e.target.value)}>
                                                            <option>---Choose Location---</option>
                                                            {getLocations?.filter((item) => item.location_status === 1).map((val, i) => {
                                                                return (
                                                                    <option key={i} value={val.location_id}>
                                                                        {val.location_address}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Field>
                                                    </div>
                                                    {scheduledDetails ? <EditDrawerTime /> : <EditDrawerTimeAdd />}
                                                    <div className="modal-footer my-3">
                                                        <button type="button" onClick={() => setDrawerEditOpen()} className="btn btn-button link-secondary mx-2">
                                                            Cancel
                                                        </button>
                                                        <button type="submit" disabled={loader ? true : false} className="btn btn-primary ">
                                                            {
                                                                loader ? <div className="spinner-border" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div> : "Submit"
                                                            }

                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }
                            }
                        </Formik>

                    </div>
                </Box>
            </Drawer >

            {/* Insert data*/}
            <Drawer anchor="right" className="modal-blur" open={isDrawerOpen} onClose={() => setIsDrawerOpen()}>
                <Box width="80rem">
                    <div className="modal-header">
                        <h5 className="modal-title">+ Add Doctor</h5>
                        <button type="button" className="btn-close" onClick={() => setIsDrawerOpen()}></button>
                    </div>
                    <div className="container">
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Emp Id <span className="text-danger">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="users_no"
                                                        placeholder="Enter Employee Id"
                                                        value={values.users_no}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    <p>Previous employee Id : {lastEmpId}</p>
                                                    {errors?.users_no && touched.users_no ? <p className="form-valid">{errors?.users_no}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Name <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" name="users_name" placeholder="Enter Name" value={values.users_name} onBlur={handleBlur} onChange={handleChange} />
                                                    {errors?.users_name && touched.users_name ? <p className="form-valid">{errors?.users_name}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Mobile <span className="text-danger">*</span></label>
                                                    <input type="number" className="form-control" name="users_mobile" placeholder="Enter Mobile Number" value={values.users_mobile} onBlur={handleBlur} onChange={handleChange} />
                                                    {errors?.users_mobile && touched.users_mobile ? <p className="form-valid">{errors?.users_mobile}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Password <span className="text-danger">*</span></label>
                                                    <input type="password" className="form-control" name="users_password" placeholder="Enter Password" value={values.users_password} onBlur={handleBlur} onChange={handleChange} />
                                                    {errors?.users_password && touched.users_password ? <p className="form-valid">{errors?.users_password}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Date of Birth <span className="text-danger">*</span></label>
                                                    <input type="date" className="form-control" name="users_dob" value={values.users_dob} onBlur={handleBlur} onChange={handleChange} />
                                                    {errors?.users_dob && touched.users_dob ? <p className="form-valid">{errors?.users_dob}</p> : null}
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Choose Gender <span className="text-danger">*</span></label>

                                                    <select className="form-control" name="users_gender" value={values.users_gender} onBlur={handleBlur} onChange={handleChange}>
                                                        <option value="">--- Choose Gender ---</option>
                                                        <option value="1">Male</option>
                                                        <option value="2">Female</option>
                                                    </select>
                                                    {errors?.users_gender && touched.users_gender ? <p className="form-valid">{errors?.users_gender}</p> : null}
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Choose Department <span className="text-danger">*</span></label>
                                                    <select className="form-control" name="users_depart_id" value={values.users_depart_id} onBlur={handleBlur} onChange={handleChange}>
                                                        <option value="">--- Choose Department ---</option>
                                                        {
                                                            getDepartments.filter((val) => val.departments_status === 1).map((item, i) => {
                                                                return (
                                                                    <option key={i} value={item.departments_id}>{item.departments_name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {errors?.users_depart_id && touched.users_depart_id ? <p className="form-valid">{errors?.users_depart_id}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Qualification <span className="text-danger">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="users_qualification"
                                                        placeholder="Enter Doctor Qualification"
                                                        value={values.users_qualification}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    {errors?.users_qualification && touched.users_qualification ? <p className="form-valid">{errors?.users_qualification}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Registration No <span className="text-danger">*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="users_doctor_reg_no"
                                                        placeholder="Enter Doctor Registration Number"
                                                        value={values.users_doctor_reg_no}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    {errors?.users_doctor_reg_no && touched.users_doctor_reg_no ? <p className="form-valid">{errors?.users_doctor_reg_no}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Experience (in yrs) <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" name="users_doctor_exp" placeholder="Enter Doctor Experience" value={values.users_doctor_exp} onBlur={handleBlur} onChange={handleChange} />
                                                    {errors?.users_doctor_exp && touched.users_doctor_exp ? <p className="form-valid">{errors?.users_doctor_exp}</p> : null}
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label className="form-label">City Name <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" name="users_city_name" placeholder="Enter City Name" value={values.users_city_name} onBlur={handleBlur} onChange={handleChange} />
                                                    {errors?.users_city_name && touched.users_city_name ? <p className="form-valid">{errors?.users_city_name}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label className="form-label">State Name <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" name="users_state_name" placeholder="Enter State Name" value={values.users_state_name} onBlur={handleBlur} onChange={handleChange} />
                                                    {errors?.users_state_name && touched.users_state_name ? <p className="form-valid">{errors?.users_state_name}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Pin code <span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control" name="users_pin" placeholder="Enter Pin Code" value={values.users_pin} onBlur={handleBlur} onChange={handleChange} />
                                                    {errors?.users_pin && touched.users_pin ? <p className="form-valid">{errors?.users_pin}</p> : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div>
                                                    <label className="form-label">Address <span className="text-danger">*</span></label>
                                                    <textarea className="form-control" rows="3" name="users_address" placeholder="Enter Address" value={values.users_address} onBlur={handleBlur} onChange={handleChange}></textarea>
                                                    {errors?.users_address && touched.users_address ? <p className="form-valid">{errors?.users_address}</p> : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">Choose Location <span className="text-danger">*</span></label>
                                            <select className="form-control" name="location_id" value={location.location_id} onChange={handleLocation}>
                                                <option value="">--- Choose Location ---</option>
                                                {getLocations?.filter((item) => item.location_status === 1).map((val, i) => {
                                                    return (
                                                        <option key={i} value={val.location_id}>
                                                            {val.location_address}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <EditDrawerTimeAdd />
                                        <div className="modal-footer my-2">
                                            <button onClick={() => setIsDrawerOpen()} type="button" className="btn btn-button link-secondary mx-2">
                                                Cancel
                                            </button>

                                            <button type="submit" disabled={loader ? true : false} className="btn btn-primary ">
                                                {
                                                    loader ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> : "Submit"
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </form>
                    </div>
                </Box >
            </Drawer >
        </div >
    );
};

export default Doctor;
