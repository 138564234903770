import React, { useState, useEffect } from 'react';
import TopNav from '../TopNav'
import SideNav from '../SideNav'
import { Form, Formik, Field, useFormik } from 'formik'
import { addPcm, editPcm } from '../schemas'
import axios from 'axios'
import { url } from '../Url'
import { Drawer, Box } from '@mui/material';
import Loader from '../Loader'
import { toast } from 'react-toastify';
import Pagination from '../Pagination'
import { purple } from '@mui/material/colors';
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
const Pcm = ({ updateAdmin, user, token }) => {
    const [pcmName, setPcmName] = useState("");
    const [empId, setEmpId] = useState("")
    const [loader, setLoader] = useState(false);
    const showPerPage = 10;
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });
    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [userId, setUserId] = useState("");
    const [userNo, setUserNo] = useState("");
    const [userName, setUserName] = useState("");
    const [userCityName, setUserCityName] = useState("");
    const [userStateName, setUserStateName] = useState("");
    const [userGender, setUserGender] = useState("");
    const [userMobile, setUserMobile] = useState("");
    const [userDob, setUserDob] = useState("");
    const [userAddress, setUserAddress] = useState("");
    // const [userDepartment, setUserDepartment] = useState("");
    const [departmentId, setDepartmentId] = useState("");
    const [userPin, setUserPin] = useState("");
    const [doctorExp, setDoctorExp] = useState("");
    const [doctorQualification, setDoctorQualification] = useState("");
    const initialValues = {
        users_no: "",
        users_password: "",
        users_name: "",
        users_city_name: "",
        users_state_name: "",
        users_gender: "",
        users_type: "3",
        users_mobile: "",
        users_dob: "",
        users_address: "",
        users_pin: "",
        users_doctor_exp: "",
        users_depart_id: "",
        users_qualification: "",
        users_doctor_reg_no: "0"
    }
    const savedValues = {
        users_id: userId,
        users_name: userName,
        users_city_name: userCityName,
        users_state_name: userStateName,
        users_gender: userGender,
        users_dob: userDob,
        users_mobile: userMobile,
        users_address: userAddress,
        users_pin: userPin,
        users_depart_id: departmentId,
        users_doctor_exp: doctorExp,
        users_qualification: doctorQualification,
        users_doctor_reg_no: "0"
    }
    const { values, touched, errors, handleBlur, handleSubmit, handleChange } = useFormik({
        initialValues: initialValues,
        validationSchema: addPcm,
        onSubmit: (values, action) => {
            handleAdd(values);
            if (values) {
                action.resetForm();
            }
        },
    })

    // console.log(savedValues);
    const handleAdd = (values) => {
        setLoader(true);
        axios.post(`${url}/addStaff?_format=json`, values, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            // console.log(res)
            if (res.status === 200) {
                setLoader();
                setOpen();
                getPcmList();
                toast.success("Pcm add successfully", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            } else {
                setLoader();
                toast.error("Pcm add failed", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        })
            .catch((err) => {
                setLoader();
                toast.error(err.response.data.msg, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
                if (err.response.status === 422 || err.response.status === 401) {
                    updateAdmin({});
                }
            });
    }
    const [getDepartments, setDepartments] = useState([])
    const getDepartment = () => {
        axios.get(`${url}/getDepartmentDetails?_format=json`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            if (res.status === 200) {
                setDepartments(res.data.data)
            } else {
                toast.error("no data found", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    useEffect(() => {
        getDepartment()
    }, []);

    const [counter, setCounter] = useState(0);
    const [getPcm, seGetPcm] = useState([]);
    const [lastEmpId, setLastEmpId] = useState("");
    const getPcmList = () => {
        counter ? setLoader() : setLoader(true);
        axios.get(`${url}/getPcmDetails?_format=json`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            // console.log(res)
            if (res.status === 200) {
                setLoader();
                setLastEmpId(res.data.data[res.data.data.length - 1].users_no)
                seGetPcm(res.data.data)
                // console.log(res.data.data.length)
            } else {
                toast.error("no data found", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            setLoader();
            if (err.response.data.code === "404") {
                toast.error("No pcm details available", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    useEffect(() => {
        getPcmList()
    }, [counter]);


    const [open, setOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const pcmData = (data) => {
        setEditOpen(true)
        setUserId(data.users_id)
        setUserNo(data.users_no)
        setUserName(data.users_name)
        setUserCityName(data.users_city_name)
        setUserStateName(data.users_state_name)
        setUserGender(data.users_gender)
        setUserMobile(data.users_mobile)
        setUserDob(data.users_dob)
        // setUserDepartment(data.departments_name)
        setDepartmentId(data.departments_id)
        setUserAddress(data.users_address)
        setUserPin(data.users_pin)
        setDoctorExp(data.users_doctor_exp)
        setDoctorQualification(data.users_qualification)
    }
    const pcmEdit = async () => {
        // alert("afadf")
        setLoader(true);
        await axios.post(`${url}/updateStaffDetails?_format=json`, savedValues, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        }).then((res) => {
            if (res.data.code === "200") {
                setLoader();
                getPcmList();
                setEditOpen();
                toast.success("Pcm Update Successfully", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            } else {
                setLoader();
                toast.error("Pcm Update failed", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            // console.log(err);
            setLoader();
            toast.error(err.response.data.msg, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                theme: "colored",
            });
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    const changePcmStatus = async (data) => {
        let { users_id, users_active } = data;
        if (users_active === 1) {
            users_active = 0;
        } else {
            users_active = 1;
        }
        await axios.post(`${url}/updateStaffStatus?_format=json`, { "users_id": users_id, "users_active": users_active }, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            if (res.data.code === "200") {
                setCounter((c) => c + 1);
                toast.success(res.data.msg, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            } else {
                setCounter((c) => c + 1);
                toast.success(res.data.msg, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    pauseOnHover: true,
                    theme: "colored",
                });
            }
        }).catch((err) => {
            toast.error(err.response.data.msg, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                theme: "colored",
            });
            if (err.response.status === 422 || err.response.status === 401) {
                updateAdmin({});
            }
        })
    }
    const [activePcm, setActivePcm] = useState("active");
    const handleActivePcm = (e) => {
        setActivePcm(e.target.value);
    };
    return (
        <div className="page">
            <SideNav />
            <TopNav updateAdmin={updateAdmin} user={user} token={token} />
            <div className="page-wrapper" >
                <div className="container-xl">
                    <div className="page-header d-print-none">
                        <div className="row g-2 align-items-center">
                            {/* <!-- Page title actions --> */}
                            <div className="col-12 col-md-auto ms-auto d-print-none">
                                <div className="btn-list">
                                    <div onClick={() => setOpen(true)} className="btn btn-primary d-none d-sm-inline-block">
                                        <i className="fas fa-plus"></i> &nbsp;
                                        Add Pcm
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2>Pcm List</h2>

                    <div className="container my-3">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="city">Search By Pcm Name </label>
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            placeholder="Enter Pcm Name"
                                            name="searchName"
                                            type="text"
                                            onChange={(e) => setPcmName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="city">Search By Emp Id </label>
                                    <div className="input-group">
                                        <input
                                            className="form-control"
                                            placeholder="Enter Employee Id"
                                            name="searchName"
                                            type="text"
                                            onChange={(e) => setEmpId(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 d-flex flex-row-reverse">
                                <ToggleButtonGroup
                                    color={activePcm === 'active' ? 'primary' : 'secondary'}
                                    value={activePcm}
                                    exclusive
                                    onChange={handleActivePcm}
                                    aria-label="Platform"
                                >
                                    <ToggleButton value="active">Active</ToggleButton>
                                    <ToggleButton value="inactive">Inactive</ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </div>
                    </div>
                    {
                        activePcm === "active" &&
                        <div className="box-datatable table-responsive" style={{ backgroundColor: "white" }}>
                            <table className="table-striped table table-vcenter card-table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Emp ID</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">dob</th>
                                        <th scope="col">gender</th>
                                        <th scope="col">department</th>
                                        <th scope="col">mobile no</th>
                                        <th scope="col">pin</th>
                                        <th scope="col">experience (in yrs)</th>
                                        <th scope="col">status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                {
                                    loader ? <Loader /> :
                                        <tbody>
                                            {
                                                getPcm?.filter((item) => {
                                                    if (pcmName === "") {
                                                        return item;
                                                    } else if (
                                                        item.users_name.toLocaleLowerCase().includes(pcmName.toLocaleLowerCase())
                                                    ) {
                                                        return item;
                                                    }
                                                    return false;
                                                }).filter((item) => {
                                                    if (empId === "") {
                                                        return item;
                                                    } else if (
                                                        item.users_no.toLocaleLowerCase().includes(empId.toLocaleLowerCase())
                                                    ) {
                                                        return item;
                                                    }
                                                    return false;
                                                }).filter((item) => item.users_active === 1).slice(pagination.start, pagination.end).map((data, i) => {
                                                    // console.log(data)
                                                    return (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>{data.users_no}</td>
                                                            <td>{data.users_name}</td>
                                                            <td>{data.users_dob?.split('-').reverse().join('/')}</td>
                                                            <td>{data.users_gender === 1 ? "Male" : data.users_gender === 2 ? "Female" : "Others"}</td>
                                                            <td>{data.departments_name}</td>
                                                            <td>{data.users_mobile}</td>
                                                            <td>{data.users_pin}</td>
                                                            <td>{data.users_doctor_exp}</td>
                                                            <td>{data.users_active === 1 ? <i onClick={() => changePcmStatus(data)} style={{ color: "green", cursor: "pointer" }} className="far fa-check-circle"></i> : <i onClick={() => changePcmStatus(data)} style={{ color: "red", cursor: "pointer" }} className="far fa-times-circle"></i>}</td>
                                                            <td onClick={() => pcmData(data)}><i style={{ color: "green" }} className="fas fa-edit"></i></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                }
                            </table>
                            <div className="mx-auto">
                                <Pagination
                                    showPerPage={showPerPage}
                                    onPaginationChange={onPaginationChange}
                                    total={getPcm.length}
                                />
                            </div>
                        </div>
                    }
                    {
                        activePcm === "inactive" &&
                        <div className="box-datatable table-responsive" style={{ backgroundColor: "white" }}>
                            <table className="table-striped table table-vcenter card-table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Emp ID</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">dob</th>
                                        <th scope="col">gender</th>
                                        <th scope="col">mobile no</th>
                                        <th scope="col">experience (in yrs)</th>
                                        <th scope="col">status</th>
                                    </tr>
                                </thead>
                                {
                                    loader ? <Loader /> :
                                        <tbody>
                                            {
                                                getPcm?.filter((item) => item.users_active === 0).map((data, i) => {
                                                    // console.log(data)
                                                    return (
                                                        <tr key={i}>
                                                            <td>{i + 1 + pagination.start}</td>
                                                            <td>{data.users_no}</td>
                                                            <td>{data.users_name}</td>
                                                            <td>{data.users_dob?.split('-').reverse().join('/')}</td>
                                                            <td>{data.users_gender === 1 ? "male" : data.users_gender === 2 ? "female" : "trans"}</td>
                                                            <td>{data.users_mobile}</td>
                                                            <td>{data.users_doctor_exp}</td>
                                                            <td>{data.users_active === 1 ? <i onClick={() => changePcmStatus(data)} style={{ color: "green", cursor: "pointer" }} className="far fa-check-circle"></i> : <i onClick={() => changePcmStatus(data)} style={{ color: "red", cursor: "pointer" }} className="far fa-times-circle"></i>}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                }
                            </table>
                        </div>
                    }
                </div>

            </div >
            {/* edit data */}
            <Drawer anchor='right' className="modal-blur" open={editOpen} onClose={() => setEditOpen()} >
                <Box width='50rem' >
                    <div className="modal-header">
                        <h5 className="modal-title"><span><i className="fa-solid fa-pencil"></i></span> Update Pcm</h5>
                        <button onClick={() => setEditOpen()} type="button" className="btn-close"></button>
                    </div>
                    <div className="container">
                        <Formik
                            initialValues={savedValues}
                            initialTouched={{
                                field: true,
                            }}
                            validationSchema={editPcm}
                            enableReinitialize
                            onSubmit={pcmEdit}
                        >
                            {({ errors, touched }) => {
                                // console.log(errors);
                                return (
                                    <Form >
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Emp Id <span className="text-danger">*</span></label>
                                                        <Field disabled type="text" className="form-control" name="users_no" value={userNo} onChange={handleChange} onBlur={handleBlur} />
                                                        {/* {errors?.users_no && touched.users_no ? <p className="form-valid">{errors?.users_no}</p> : null} */}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Name <span className="text-danger">*</span></label>
                                                        <Field type="text" className="form-control" name="users_name" value={userName} onBlur={handleBlur} onChange={(e) => setUserName(e.target.value)} />
                                                        {errors?.users_name && touched.users_name ? <p className="form-valid">{errors?.users_name}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Mobile <span className="text-danger">*</span></label>
                                                        <Field type="tel" className="form-control" name="users_mobile" value={userMobile} onBlur={handleBlur} onChange={(e) => setUserMobile(e.target.value)} />
                                                        {errors?.users_mobile && touched.users_mobile ? <p className="form-valid">{errors?.users_mobile}</p> : null}
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Date of Birth <span className="text-danger">*</span></label>
                                                        <Field type="date" className="form-control" name="users_dob" value={userDob} onBlur={handleBlur} onChange={(e) => setUserDob(e.target.value)} />
                                                        {errors?.users_dob && touched.users_dob ? <p className="form-valid">{errors?.users_dob}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">City Name <span className="text-danger">*</span></label>
                                                        <Field type="text" className="form-control" name="users_city_name" value={userCityName} onBlur={handleBlur} onChange={(e) => setUserCityName(e.target.value)} />
                                                        {errors?.users_city_name && touched.users_city_name ? <p className="form-valid">{errors?.users_city_name}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">State Name <span className="text-danger">*</span></label>
                                                        <Field type="text" className="form-control" name="users_state_name" value={userStateName} onBlur={handleBlur} onChange={(e) => setUserStateName(e.target.value)} />
                                                        {errors?.users_state_name && touched.users_state_name ? <p className="form-valid">{errors?.users_state_name}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Choose Gender <span className="text-danger">*</span></label>
                                                        <Field className="form-control" as="select" name="users_gender" value={userGender} onBlur={handleBlur} onChange={(e) => setUserGender(e.target.value)}>
                                                            <option value=''>--- Choose gender ---</option>
                                                            <option value="1">Male</option>
                                                            <option value="2">Female</option>
                                                        </Field>
                                                        {errors?.users_gender && touched.users_gender ? <p className="form-valid">{errors?.users_gender}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Choose Department <span className="text-danger">*</span></label>
                                                        <Field as="select" className="form-control" name="users_depart_id" value={departmentId} onBlur={handleBlur} onChange={(e) => setDepartmentId(e.target.value)}>
                                                            <option value=''>--- Choose Department ---</option>
                                                            {getDepartments.filter((val) => val.departments_status === 1).map((item, i) => {
                                                                return (
                                                                    <option key={i} value={item.departments_id}>
                                                                        {item.departments_name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Field>
                                                        {errors?.users_depart_id && touched.users_depart_id ? <p className="form-valid">{errors?.users_depart_id}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Pin <span className="text-danger">*</span></label>
                                                        <Field type="number" className="form-control" pattern="\d*" maxLength="6" name="users_pin" value={userPin} onBlur={handleBlur} onChange={(e) => setUserPin(e.target.value)} />
                                                        {errors?.users_pin && touched.users_pin ? <p className="form-valid">{errors?.users_pin}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Qualification <span className="text-danger">*</span></label>
                                                        <Field type="text" className="form-control" name="users_qualification" value={doctorQualification} onBlur={handleBlur} onChange={(e) => setDoctorQualification(e.target.value)} />
                                                        {errors?.users_qualification && touched.users_qualification ? <p className="form-valid">{errors?.users_qualification}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Experience (in yrs) <span className="text-danger">*</span></label>
                                                        <Field type="text" className="form-control" name="users_doctor_exp" value={doctorExp} onBlur={handleBlur} onChange={(e) => setDoctorExp(e.target.value)} />
                                                        {errors?.users_doctor_exp && touched.users_doctor_exp ? <p className="form-valid">{errors?.users_doctor_exp}</p> : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div>
                                                        <label className="form-label">Address <span className="text-danger">*</span></label>
                                                        <textarea className="form-control" rows="3" name="users_address" value={userAddress} onBlur={handleBlur} onChange={(e) => setUserAddress(e.target.value)}></textarea>
                                                        {errors?.users_address && touched.users_address ? <p className="form-valid">{errors?.users_address}</p> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer my-2">
                                            <button onClick={() => setEditOpen()} type="button" className="btn btn-button link-secondary mx-2">
                                                Cancel
                                            </button>
                                            <button type="submit" disabled={loader ? true : false} className="btn btn-primary " >
                                                {
                                                    loader ? <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> : "Submit"
                                                }
                                            </button>
                                        </div>
                                    </Form>
                                )
                            }
                            }
                        </Formik>
                    </div>
                </Box>
            </Drawer >
            {/* Insert Data */}
            <Drawer anchor='right' className="modal-blur" open={open} onClose={() => setOpen()} >
                <Box width='50rem' >
                    <div className="modal-header">
                        <h5 className="modal-title">+ Add Pcm</h5>
                        <button type="button" className="btn-close" onClick={() => setOpen()}></button>
                    </div>
                    <div className="container">
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Emp Id <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name="users_no" placeholder="Enter Employee Id" value={values.users_no} onChange={handleChange} onBlur={handleBlur} />
                                            <p>Previous employee Id : {lastEmpId}</p>
                                            {errors?.users_no && touched.users_no ? <p className="form-valid">{errors?.users_no}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name="users_name" placeholder="Enter Name" value={values.users_name} onBlur={handleBlur} onChange={handleChange} />
                                            {errors?.users_name && touched.users_name ? <p className="form-valid">{errors?.users_name}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Mobile <span className="text-danger">*</span></label>
                                            <input type="numbner" className="form-control" name="users_mobile" placeholder="Enter Mobile Number" value={values.users_mobile} onBlur={handleBlur} onChange={handleChange} />
                                            {errors?.users_mobile && touched.users_mobile ? <p className="form-valid">{errors?.users_mobile}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Password <span className="text-danger">*</span></label>
                                            <input type="password" className="form-control" name="users_password" placeholder="Enter Password" value={values.users_password} onBlur={handleBlur} onChange={handleChange} />
                                            {errors?.users_password && touched.users_password ? <p className="form-valid">{errors?.users_password}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Date of Birth <span className="text-danger">*</span></label>
                                            <input type="date" className="form-control" name="users_dob" value={values.users_dob} onBlur={handleBlur} onChange={handleChange} />
                                            {errors?.users_dob && touched.users_dob ? <p className="form-valid">{errors?.users_dob}</p> : null}
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Choose Gender <span className="text-danger">*</span></label>

                                            <select className="form-control" name="users_gender" value={values.users_gender} onBlur={handleBlur} onChange={handleChange}>
                                                <option value=''>--- Choose gender ---</option>
                                                <option value="1">Male</option>
                                                <option value="2">Female</option>
                                            </select>
                                            {errors?.users_gender && touched?.users_gender ? <p className="form-valid">{errors?.users_gender}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Choose Department <span className="text-danger">*</span></label>

                                            <select className="form-control" name="users_depart_id" value={values.users_depart_id} onBlur={handleBlur} onChange={handleChange}>
                                                <option value=''>--- Choose Department ---</option>
                                                {
                                                    getDepartments.filter((val) => val.departments_status === 1).map((item, i) => {
                                                        return (
                                                            <option key={i} value={item.departments_id}>{item.departments_name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {errors?.users_depart_id && touched.users_depart_id ? <p className="form-valid">{errors?.users_depart_id}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Qualification <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name="users_qualification" placeholder="Enter Doctor Qualification" value={values.users_qualification} onBlur={handleBlur} onChange={handleChange} />
                                            {errors?.users_qualification && touched.users_qualification ? <p className="form-valid">{errors?.users_qualification}</p> : null}
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Experience (in yrs) <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name="users_doctor_exp" placeholder="Enter Doctor Experience" value={values.users_doctor_exp} onBlur={handleBlur} onChange={handleChange} />
                                            {errors?.users_doctor_exp && touched.users_doctor_exp ? <p className="form-valid">{errors?.users_doctor_exp}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">City Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name="users_city_name" placeholder="Enter City Name" value={values.users_city_name} onBlur={handleBlur} onChange={handleChange} />
                                            {errors?.users_city_name && touched.users_city_name ? <p className="form-valid">{errors?.users_city_name}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">State Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name="users_state_name" placeholder="Enter State name" value={values.users_state_name} onBlur={handleBlur} onChange={handleChange} />
                                            {errors?.users_state_name && touched.users_state_name ? <p className="form-valid">{errors?.users_state_name}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Pin Code <span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" name="users_pin" placeholder="Enter Pin Code" value={values.users_pin} onBlur={handleBlur} onChange={handleChange} />
                                            {errors?.users_pin && touched.users_pin ? <p className="form-valid">{errors?.users_pin}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div>
                                            <label className="form-label">Address <span className="text-danger">*</span></label>
                                            <textarea className="form-control" rows="3" name="users_address" placeholder="Enter Address" value={values.users_address} onBlur={handleBlur} onChange={handleChange}></textarea>
                                            {errors?.users_address && touched.users_address ? <p className="form-valid">{errors?.users_address}</p> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer my-2">
                                <button onClick={() => setOpen()} type="button" className="btn btn-button link-secondary mx-2" >
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary " >
                                    {
                                        loader ? <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> : "Submit"
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </Box>
            </Drawer >
        </div >
    );
}

export default Pcm;